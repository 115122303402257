const config = {
    // customisation
    env: 'levacsolar',
    color: '#696969',
    rgbPDF: {
        r: 0.039,
        g: 0.341,
        b: 0.584
    },
    header: {
        backgroundPosition: '100% 65%',
        logoContainerPadding: 50,
        logo: {
            height: 100,
            width: 100,
            backgroundColor: 'white'
        },
        title: 'Mon projet photovoltaïque'
    },
    name: 'Levac-solar.com',
    custom: {
        // ajouter une clé pour ajouter une ligne
        1: '12 Avenue Lionel Terray',
        2: '69330 MEYZIEU',
        3: '04.78.69.98.68'
    },
    mail: 'contact@levac-solar.com',
    privacy: '',
    legal: '',
    modalType: 'achille', // 'achille' or ''
    hotline: '', // '09 00 00 00 00' or ''
    hotjar: {
        hjid: 0,
        hjsv: 0
    },
    googleAnalyticId: '',

    apiGoogle: {
        key: 'AIzaSyCRMyPFYFvqYqWSGAcoWBDNSSzy7ZliNxU',
        libraries: ['geometry', 'places'],
    },
    // internal urls
        sendFormUrl: 'https://simulateur-photovoltaique.levac-solar.com/sendPDF',
        fetchBill: 'https://simulateur-photovoltaique.levac-solar.com/estimated-invoice',
        fetchEstimation: 'https://simulateur-photovoltaique.levac-solar.com/pre-calculate',

    // external urls
    achilleUrl: 'https://levac-solar.com/94-domotique',
};

export default config;

